export class Vector2D {
  public readonly x: number;
  public readonly y: number;

  constructor();
  constructor(x: number, y: number);
  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }

  public plus(other: Vector2D): Vector2D {
    return new Vector2D(this.x + other.x, this.y + other.y);
  }

  public minus(other: Vector2D): Vector2D {
    return new Vector2D(this.x - other.x, this.y - other.y);
  }

  public scale(other: number): Vector2D {
    return new Vector2D(this.x * other, this.y * other);
  }

  public equals(other: Vector2D): boolean {
    return this.x === other.x && this.y === other.y;
  }
}
