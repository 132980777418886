<template>
  <div class="modal is-active">
    <div class="modal-background" />

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Login</p>
        <button class="delete" @click.left="cancel" />
      </header>

      <section class="modal-card-body">
        <div class="field">
          <label class="label">Username</label>
          <div class="control">
            <input
              ref="username_input"
              class="input"
              type="text"
              v-model="username"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Passwort</label>
          <div class="control">
            <input class="input" type="password" v-model="password" />
          </div>
        </div>
      </section>

      <footer class="modal-card-foot is-flex is-justify-content-space-around">
        <BulmaButton
          class="is-success"
          @click.left="submit"
          icon="fa-solid fa-unlock"
          text="Login"
        />
        <BulmaButton
          class="is-danger"
          @click.left="cancel"
          icon="fa-solid fa-circle-xmark"
          text="Abbrechen"
        />
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import BulmaButton from "./bulma/Button.vue";

@Options({
  components: {
    BulmaButton,
  },
  props: {
    visible: Boolean,
  },
  emits: ["cancel", "submit"],
})
export default class extends Vue {
  public username = "";
  public password = "";

  private on_keydown(e: KeyboardEvent) {
    if (e.key == "Enter") this.submit();
    else if (e.key == "Escape") this.cancel();
  }

  public mounted(): void {
    window.addEventListener("keydown", this.on_keydown);

    this.$nextTick(() => {
      if (!(this.$refs.username_input instanceof HTMLElement)) return;
      this.$refs.username_input.focus();
    });
  }

  public beforeUnmount(): void {
    window.removeEventListener("keydown", this.on_keydown);
  }

  public submit(): void {
    this.$emit("submit", [this.username, this.password]);
  }

  public cancel(): void {
    this.$emit("cancel");
  }
}
</script>
