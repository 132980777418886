<template>
  <ConfigView />
  <CalendarAssistant />
  <DoorMapEditor />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import CalendarAssistant from "./CalendarAssistant.vue";
import ConfigView from "./ConfigView.vue";
import DoorMapEditor from "./DoorMapEditor.vue";

@Options({
  components: {
    ConfigView,
    CalendarAssistant,
    DoorMapEditor,
  },
})
export default class extends Vue {}
</script>
