<template>
  <SVGRect
    style="cursor: text"
    :rectangle="door.position"
    :variant="editing ? 'success' : 'primary'"
    @click.left="on_click"
    visible
  >
    <input
      v-if="editing"
      v-model="day_str"
      ref="day_input"
      class="input is-large"
      type="number"
      :min="MIN_DAY"
      placeholder="Tag"
      @keydown="on_keydown"
    />
    <div v-else class="has-text-danger">
      {{ door.day > 0 ? door.day : "*" }}
    </div>
  </SVGRect>
</template>

<script lang="ts">
import { Door } from "@/lib/door";
import { Options, Vue } from "vue-class-component";

import SVGRect from "../calendar/SVGRect.vue";

@Options({
  components: {
    SVGRect,
  },
  props: {
    door: Door,
  },
})
export default class extends Vue {
  public door!: Door;
  public readonly MIN_DAY = Door.MIN_DAY;

  public day_str = "";
  public editing = false;

  private toggle_editing() {
    this.day_str = String(this.door.day);
    this.editing = !this.editing;
  }

  public on_click(event: MouseEvent) {
    if (!(event.target instanceof HTMLDivElement)) {
      return;
    }

    if (!this.editing) {
      const day_input_focus = () => {
        if (this.$refs.day_input instanceof HTMLInputElement) {
          this.$refs.day_input.select();
          return;
        }

        this.$nextTick(day_input_focus);
      };
      day_input_focus();
    } else {
      this.door.day = this.day_str;
    }

    this.toggle_editing();
  }

  public on_keydown(event: KeyboardEvent) {
    if (!this.editing) {
      return;
    }

    if (event.key === "Enter") {
      this.door.day = this.day_str;
      this.toggle_editing();
    } else if (event.key === "Delete") {
      this.door.day = -1;
      this.toggle_editing();
    } else if (event.key === "Escape") {
      this.toggle_editing();
    }
  }
}
</script>
