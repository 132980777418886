import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "button" }
const _hoisted_2 = {
  key: 0,
  class: "icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    (_ctx.text === undefined)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
          (_ctx.icon !== undefined)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: _ctx.icon,
                "beat-fade": _ctx.busy
              }, null, 8, ["icon", "beat-fade"]))
            : _createCommentVNode("", true)
        ])
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.icon !== undefined)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _renderSlot(_ctx.$slots, "default", {}, () => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: _ctx.icon,
                    "beat-fade": _ctx.busy
                  }, null, 8, ["icon", "beat-fade"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
        ], 64))
  ]))
}