<template>
  <MultiModal @handle="modal_handle" />

  <BulmaDrawer header="Kalender-Assistent" @open="on_open" refreshable>
    <div class="card-content">
      <div class="content">
        <p>Hervorgehobenen Tagen wurde kein Buchstabe zugewiesen.</p>

        <h3>Zuordnung Buchstaben</h3>
        <div class="tags are-medium">
          <template v-for="(data, day) in day_data" :key="`part-${day}`">
            <span v-if="data.part === ''" class="tag is-warning">
              {{ day }}
            </span>
            <span v-else class="tag is-info">
              {{ day }}: {{ data.part.split("").join(", ") }}
            </span>
          </template>
        </div>

        <h3>Zuordnung Bilder</h3>
        <div class="tags are-medium">
          <span
            v-for="(data, day) in day_data"
            :key="`image-${day}`"
            :class="'tag is-' + (data.part === '' ? 'warning' : 'primary')"
          >
            {{ day }}: {{ data.image_name }}
          </span>
        </div>

        <h3>Alle Türchen</h3>
        <div class="tags are-medium">
          <BulmaButton
            v-for="(data, day) in day_data"
            :key="`btn-${day}`"
            :class="'tag is-' + (data.part === '' ? 'warning' : 'info')"
            icon="fa-solid fa-door-open"
            :text="day"
            @click.left="door_click(day)"
          />
        </div>
      </div>
    </div>
  </BulmaDrawer>
</template>

<script lang="ts">
import { NumStrDict, objForEach } from "@/lib/api";
import { Options, Vue } from "vue-class-component";

import MultiModal from "../MultiModal.vue";
import BulmaButton from "../bulma/Button.vue";
import BulmaDrawer from "../bulma/Drawer.vue";

@Options({
  components: {
    BulmaButton,
    BulmaDrawer,
    MultiModal,
  },
})
export default class extends Vue {
  public day_data: {
    [day: number]: {
      part: string;
      image_name: string;
    };
  } = {};

  private multi_modal?: MultiModal;

  public modal_handle(modal: MultiModal) {
    this.multi_modal = modal;
  }

  public on_open(ready: () => void, fail: () => void): void {
    Promise.all([
      this.$advent22.api_get<NumStrDict>("admin/day_parts"),
      this.$advent22.api_get<NumStrDict>("admin/day_image_names"),
    ])
      .then(([day_parts, day_image_names]) => {
        const _ensure_day_in_data = (day: number) => {
          if (!(day in this.day_data)) {
            this.day_data[day] = { part: "", image_name: "" };
          }
        };

        objForEach(day_parts, (day, part) => {
          _ensure_day_in_data(day);
          this.day_data[day].part = part;
        });

        objForEach(day_image_names, (day, image_name) => {
          _ensure_day_in_data(day);
          this.day_data[day].image_name = image_name;
        });

        ready();
      })
      .catch(fail);
  }

  public door_click(day: number) {
    if (this.multi_modal === undefined) return;
    this.multi_modal.show_progress();

    this.$advent22
      .api_get_blob(`user/image_${day}`)
      .then((image_src) =>
        this.multi_modal!.show_image(image_src, this.$advent22.name_door(day)),
      )
      .catch(() => this.multi_modal!.hide());
  }
}
</script>
