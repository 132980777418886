<template>
  <MultiModal @handle="modal_handle" />

  <BulmaToast @handle="toast_handle" class="content">
    <p>
      Du hast noch keine Türchen geöffnet, vielleicht gibt es ein Anzeigeproblem
      in Deinem Webbrowser?
    </p>
    <div class="level">
      <div class="level-item">
        <BulmaButton
          class="is-success"
          text="Türchen anzeigen"
          @click.left="
            store.is_touch_device = true;
            toast?.hide();
          "
        />
      </div>
      <div class="level-item">
        <BulmaButton
          class="is-danger"
          text="Ich möchte selbst suchen"
          @click.left="toast?.hide()"
        />
      </div>
    </div>
  </BulmaToast>

  <figure>
    <div class="image is-unselectable">
      <img :src="store.calendar_background_image" />
      <ThouCanvas>
        <CalendarDoor
          v-for="(door, index) in doors"
          :key="`door-${index}`"
          :door="door"
          :visible="store.is_touch_device"
          :title="$advent22.name_door(door.day)"
          @click="door_click(door.day)"
          style="cursor: pointer"
        />
      </ThouCanvas>
    </div>
  </figure>
</template>

<script lang="ts">
import { Door } from "@/lib/door";
import { advent22Store } from "@/plugins/store";
import { Options, Vue } from "vue-class-component";

import MultiModal from "./MultiModal.vue";
import BulmaButton from "./bulma/Button.vue";
import BulmaToast from "./bulma/Toast.vue";
import CalendarDoor from "./calendar/CalendarDoor.vue";
import ThouCanvas from "./calendar/ThouCanvas.vue";

@Options({
  components: {
    MultiModal,
    BulmaButton,
    BulmaToast,
    ThouCanvas,
    CalendarDoor,
  },
  props: {
    doors: Array,
  },
})
export default class extends Vue {
  public readonly doors!: Door[];
  public readonly store = advent22Store();

  private multi_modal?: MultiModal;

  public toast?: BulmaToast;
  private toast_timeout?: number;

  public modal_handle(modal: MultiModal) {
    this.multi_modal = modal;
  }

  public toast_handle(toast: BulmaToast) {
    this.toast = toast;

    if (this.store.is_touch_device) return;

    this.store.when_initialized(() => {
      this.toast_timeout = setTimeout(() => {
        if (this.store.user_doors.length === 0) return;
        if (this.store.is_touch_device) return;

        this.toast!.show({ duration: 600000, type: "is-warning" });
      }, 10e3);
    });
  }

  public door_click(day: number) {
    if (this.toast_timeout !== undefined) clearTimeout(this.toast_timeout);
    this.toast?.hide();

    if (this.multi_modal === undefined) return;
    this.multi_modal.show_progress();

    this.$advent22
      .api_get_blob(`user/image_${day}`)
      .then((image_src) => {
        this.multi_modal!.show_image(image_src, this.$advent22.name_door(day));
      })
      .catch((error) => {
        this.$advent22.alert_user_error(error);
        this.multi_modal!.hide();
      });
  }

  public beforeUnmount(): void {
    this.toast?.hide();
  }
}
</script>
