<template>
  <button class="button">
    <slot v-if="text === undefined" name="default">
      <font-awesome-icon
        v-if="icon !== undefined"
        :icon="icon"
        :beat-fade="busy"
      />
    </slot>
    <template v-else>
      <span v-if="icon !== undefined" class="icon">
        <slot name="default">
          <font-awesome-icon :icon="icon" :beat-fade="busy" />
        </slot>
      </span>
      <span>{{ text }}</span>
    </template>
  </button>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    icon: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
})
export default class extends Vue {
  public icon?: string;
  public text?: string;
  public busy!: boolean;
}
</script>
