<template>
  <span>Eingabemodus:&nbsp;</span>
  <BulmaButton
    v-bind="$attrs"
    :icon="
      'fa-solid fa-' +
      (store.is_touch_device ? 'hand-pointer' : 'arrow-pointer')
    "
    :text="store.is_touch_device ? 'Touch' : 'Desktop'"
    @click.left="store.toggle_touch_device"
  />
</template>

<script lang="ts">
import { advent22Store } from "@/plugins/store";
import { Options, Vue } from "vue-class-component";

import BulmaButton from "./bulma/Button.vue";

@Options({
  components: {
    BulmaButton,
  },
})
export default class extends Vue {
  public readonly store = advent22Store();
}
</script>
