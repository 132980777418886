<template>
  <BulmaDrawer header="Konfiguration" @open="on_open" refreshable>
    <div class="card-content">
      <div class="columns">
        <div class="column is-one-third">
          <div class="content">
            <h3>Lösung</h3>
            <dl>
              <dt>Wert</dt>
              <dd>
                Eingabe:
                <span class="is-family-monospace">
                  "{{ admin_config_model.solution.value }}"
                </span>
              </dd>
              <dd>
                Ausgabe:
                <span class="is-family-monospace">
                  "{{ admin_config_model.solution.clean }}"
                </span>
              </dd>

              <dt>Transformation</dt>
              <dd>
                Whitespace:
                <span class="is-uppercase is-family-monospace">
                  {{ admin_config_model.solution.whitespace }}
                </span>
              </dd>
              <dd>
                Sonderzeichen:
                <span class="is-uppercase is-family-monospace">
                  {{ admin_config_model.solution.special_chars }}
                </span>
              </dd>
              <dd>
                Buchstaben:
                <span class="is-uppercase is-family-monospace">
                  {{ admin_config_model.solution.case }}
                </span>
              </dd>
            </dl>

            <h3>Rätsel</h3>
            <dl>
              <dt>Offene Türchen</dt>
              <dd>{{ store.user_doors.length }}</dd>

              <dt>Zeit zum nächsten Türchen</dt>
              <dd v-if="store.next_door_target === null">
                Kein nächstes Türchen
              </dd>
              <dd v-else><CountDown :until="store.next_door_target" /></dd>

              <dt>Erstes Türchen</dt>
              <dd>{{ fmt_puzzle_date("first") }}</dd>

              <dt>Nächstes Türchen</dt>
              <dd>{{ fmt_puzzle_date("next") }}</dd>

              <dt>Letztes Türchen</dt>
              <dd>{{ fmt_puzzle_date("last") }}</dd>

              <dt>Rätsel schließt nach</dt>
              <dd>{{ fmt_puzzle_date("end") }}</dd>

              <dt>Zufalls-Seed</dt>
              <dd class="is-family-monospace">
                "{{ admin_config_model.puzzle.seed }}"
              </dd>

              <dt>Extra-Tage</dt>
              <dd>
                <template
                  v-for="(day, index) in admin_config_model.puzzle.extra_days"
                  :key="`extra_day-${index}`"
                >
                  <span>
                    <template v-if="index > 0">, </template>
                    {{ day }}
                  </span>
                </template>
              </dd>

              <dt>Leere Türchen</dt>
              <dd v-if="admin_config_model.puzzle.skip_empty">Überspringen</dd>
              <dd v-else>Anzeigen</dd>
            </dl>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="content">
            <h3>Kalender</h3>
            <dl>
              <dt>Definition</dt>
              <dd>{{ admin_config_model.calendar.config_file }}</dd>

              <dt>Hintergrundbild</dt>
              <dd>{{ admin_config_model.calendar.background }}</dd>

              <dt>Favicon</dt>
              <dd>{{ admin_config_model.calendar.favicon }}</dd>

              <dt>Türchen ({{ doors.length }} Stück)</dt>
              <dd>
                <template v-for="(door, index) in doors" :key="`door-${index}`">
                  <span>
                    <template v-if="index > 0">, </template>
                    {{ door.day }}
                  </span>
                </template>
              </dd>
            </dl>

            <h3>Bilder</h3>
            <dl>
              <dt>Größe</dt>
              <dd>{{ admin_config_model.image.size }} px</dd>

              <dt>Rand</dt>
              <dd>{{ admin_config_model.image.border }} px</dd>

              <dt>Schriftarten</dt>
              <dd
                v-for="(font, index) in admin_config_model.fonts"
                :key="`font-${index}`"
              >
                {{ font.file }} ({{ font.size }} pt)
              </dd>
            </dl>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="content">
            <h3>WebDAV</h3>
            <dl>
              <dt>URL</dt>
              <dd>{{ admin_config_model.webdav.url }}</dd>

              <dt>Zugangsdaten</dt>
              <dd class="is-family-monospace">
                <BulmaSecret @load="load_dav_credentials">
                  <span class="tag is-danger">user</span>
                  {{ dav_credentials[0] }}
                  <br />
                  <span class="tag is-danger">pass</span>
                  {{ dav_credentials[1] }}
                </BulmaSecret>
              </dd>

              <dt>Cache-Dauer</dt>
              <dd>{{ admin_config_model.webdav.cache_ttl }} s</dd>

              <dt>Konfigurationsdatei</dt>
              <dd>{{ admin_config_model.webdav.config_file }}</dd>
            </dl>
          </div>

          <div class="content">
            <h3>Sonstige</h3>
            <dl>
              <dt>Redis</dt>
              <dd>Host: {{ admin_config_model.redis.host }}</dd>
              <dd>Port: {{ admin_config_model.redis.port }}</dd>
              <dd>Datenbank: {{ admin_config_model.redis.db }}</dd>
              <dd>Protokoll: {{ admin_config_model.redis.protocol }}</dd>

              <dt>UI-Admin</dt>
              <dd class="is-family-monospace">
                <BulmaSecret @load="load_ui_credentials">
                  <span class="tag is-danger">user</span>
                  {{ ui_credentials[0] }}
                  <br />
                  <span class="tag is-danger">pass</span>
                  {{ ui_credentials[1] }}
                </BulmaSecret>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </BulmaDrawer>
</template>

<script lang="ts">
import { AdminConfigModel, Credentials, DoorSaved } from "@/lib/api";
import { advent22Store } from "@/plugins/store";
import { DateTime } from "luxon";
import { Options, Vue } from "vue-class-component";

import BulmaDrawer from "../bulma/Drawer.vue";
import BulmaSecret from "../bulma/Secret.vue";
import CountDown from "../CountDown.vue";

@Options({
  components: {
    BulmaDrawer,
    BulmaSecret,
    CountDown,
  },
})
export default class extends Vue {
  public readonly store = advent22Store();

  public admin_config_model: AdminConfigModel = {
    solution: {
      value: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      whitespace: "KEEP",
      special_chars: "KEEP",
      case: "KEEP",
      clean: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    },
    puzzle: {
      first: "2023-12-01",
      next: "2023-12-01",
      last: "2023-12-24",
      end: "2024-04-01",
      seed: "",
      extra_days: [],
      skip_empty: true,
    },
    calendar: {
      config_file: "lorem ipsum",
      background: "dolor sit",
      favicon: "sit amet",
    },
    image: {
      size: 500,
      border: 0,
    },
    fonts: [{ file: "consetetur", size: 0 }],
    redis: {
      host: "0.0.0.0",
      port: 6379,
      db: 0,
      protocol: 3,
    },
    webdav: {
      url: "sadipscing elitr",
      cache_ttl: 0,
      config_file: "sed diam nonumy",
    },
  };
  public doors: DoorSaved[] = [];
  public dav_credentials: Credentials = ["", ""];
  public ui_credentials: Credentials = ["", ""];

  public fmt_puzzle_date(name: keyof AdminConfigModel["puzzle"]): string {
    const iso_date = this.admin_config_model.puzzle[name];
    if (!(typeof iso_date == "string")) return "-";

    return DateTime.fromISO(iso_date).toLocaleString(DateTime.DATE_SHORT);
  }

  public on_open(ready: () => void, fail: () => void): void {
    Promise.all([
      this.store.update(),
      this.$advent22.api_get<AdminConfigModel>("admin/config_model"),
      this.$advent22.api_get<DoorSaved[]>("admin/doors"),
    ])
      .then(([store_update, admin_config_model, doors]) => {
        store_update; // discard value

        this.admin_config_model = admin_config_model;
        this.doors = doors;

        ready();
      })
      .catch(fail);
  }

  public load_dav_credentials(): void {
    this.$advent22
      .api_get<Credentials>("admin/dav_credentials")
      .then((creds) => (this.dav_credentials = creds))
      .catch(() => {});
  }

  public load_ui_credentials(): void {
    this.$advent22
      .api_get<Credentials>("admin/ui_credentials")
      .then((creds) => (this.ui_credentials = creds))
      .catch(() => {});
  }
}
</script>

<style scoped>
dd {
  overflow-x: auto;
}
</style>
