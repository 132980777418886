import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfigView = _resolveComponent("ConfigView")!
  const _component_CalendarAssistant = _resolveComponent("CalendarAssistant")!
  const _component_DoorMapEditor = _resolveComponent("DoorMapEditor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfigView),
    _createVNode(_component_CalendarAssistant),
    _createVNode(_component_DoorMapEditor)
  ], 64))
}