<template>
  <div class="card-content">
    <div class="content is-small">
      <h3>Steuerung</h3>
      <ul>
        <li>Linksklick + Ziehen: Neues Türchen erstellen</li>
        <li>Rechtsklick + Ziehen: Türchen verschieben</li>
        <li>Doppel- oder Mittelklick: Türchen löschen</li>
      </ul>
    </div>
    <figure class="image is-unselectable">
      <img :src="store.calendar_background_image" />
      <DoorCanvas :doors="doors" />
    </figure>
  </div>
</template>

<script lang="ts">
import { Door } from "@/lib/door";
import { advent22Store } from "@/plugins/store";
import { Options, Vue } from "vue-class-component";

import DoorCanvas from "./DoorCanvas.vue";

@Options({
  components: {
    DoorCanvas,
  },
  props: {
    doors: Array,
  },
})
export default class extends Vue {
  public doors!: Door[];
  public readonly store = advent22Store();
}
</script>
