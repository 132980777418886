import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    version: "1.1",
    viewBox: "0 0 1000 1000",
    preserveAspectRatio: "none",
    onContextmenu: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
    onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.transform_mouse_event && _ctx.transform_mouse_event(...args))),
    onMousemove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.transform_mouse_event && _ctx.transform_mouse_event(...args))),
    onMouseup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.transform_mouse_event && _ctx.transform_mouse_event(...args))),
    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.transform_mouse_event && _ctx.transform_mouse_event(...args))),
    onDblclick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.transform_mouse_event && _ctx.transform_mouse_event(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 32))
}