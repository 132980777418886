import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalendarDoor = _resolveComponent("CalendarDoor")!
  const _component_SVGRect = _resolveComponent("SVGRect")!
  const _component_ThouCanvas = _resolveComponent("ThouCanvas")!

  return (_openBlock(), _createBlock(_component_ThouCanvas, {
    onMousedown: [
      _withModifiers(_ctx.draw_start, ["left"]),
      _withModifiers(_ctx.drag_start, ["right"])
    ],
    onMouseup: [
      _withModifiers(_ctx.draw_finish, ["left"]),
      _withModifiers(_ctx.drag_finish, ["right"]),
      _withModifiers(_ctx.remove_rect, ["middle"])
    ],
    onMousemove: _ctx.on_mousemove,
    onDblclick: _withModifiers(_ctx.remove_rect, ["left"])
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doors, (door, index) => {
        return (_openBlock(), _createBlock(_component_CalendarDoor, {
          key: `door-${index}`,
          door: door,
          force_visible: ""
        }, null, 8, ["door"]))
      }), 128)),
      (_ctx.preview_visible)
        ? (_openBlock(), _createBlock(_component_SVGRect, {
            key: 0,
            variant: "success",
            rectangle: _ctx.preview_rect,
            visible: ""
          }, null, 8, ["rectangle"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onMousedown", "onMouseup", "onMousemove", "onDblclick"]))
}