import { Advent22Plugin } from "@/plugins/advent22";
import { FontAwesomePlugin } from "@/plugins/fontawesome";
import { advent22Store } from "@/plugins/store";
import * as bulmaToast from "bulma-toast";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";

import "@/main.scss";

const app = createApp(App);

app.use(Advent22Plugin);
app.use(FontAwesomePlugin);

app.use(createPinia());
advent22Store().init();

app.mount("#app");

bulmaToast.setDefaults({
  duration: 10e3,
  pauseOnHover: true,
  dismissible: true,
  closeOnClick: false,
  type: "is-white",
  position: "top-center",
  animate: { in: "backInDown", out: "backOutUp" },
});
