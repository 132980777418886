<template>
  <div class="modal is-active" v-if="active" @click="dismiss()">
    <div class="modal-background" />

    <div class="modal-content" style="max-height: 100vh; max-width: 95vw">
      <template v-if="progress">
        <progress class="progress is-primary" max="100" />
      </template>
      <template v-else>
        <figure>
          <figcaption class="tag is-primary">
            {{ caption }}
          </figcaption>
          <div class="image is-square">
            <img :src="image_src" alt="Kalender-Bild" />
          </div>
        </figure>
      </template>
    </div>

    <button
      v-if="!progress"
      class="modal-close is-large has-background-primary"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["handle"],
})
export default class extends Vue {
  public active = false;
  public progress = false;
  public image_src = "";
  public caption = "";

  private on_keydown(e: KeyboardEvent) {
    if (e.key == "Escape") this.dismiss();
  }

  public created(): void {
    this.$emit("handle", this);
  }

  public mounted(): void {
    window.addEventListener("keydown", this.on_keydown);
  }

  public beforeUnmount(): void {
    window.removeEventListener("keydown", this.on_keydown);
  }

  public show() {
    this.active = true;
  }

  public hide() {
    this.active = false;
  }

  public dismiss() {
    // Cannot dismiss the "loading" screen
    if (this.active && this.progress) return;

    this.active = false;
  }

  public show_image(src: string, caption: string = "") {
    this.progress = false;
    this.image_src = src;
    this.caption = caption;
    this.show();
  }

  public show_progress() {
    this.progress = true;
    this.show();
  }
}
</script>
