<template>
  <LoginModal v-if="modal_visible" @submit="on_submit" @cancel="on_cancel" />

  <BulmaButton
    v-bind="$attrs"
    :icon="'fa-solid fa-toggle-' + (store.is_admin ? 'on' : 'off')"
    :busy="is_busy"
    text="Admin"
    @click.left="on_click"
  />
</template>

<script lang="ts">
import { Credentials } from "@/lib/api";
import { advent22Store } from "@/plugins/store";
import { Options, Vue } from "vue-class-component";

import BulmaButton from "./bulma/Button.vue";
import LoginModal from "./LoginModal.vue";

@Options({
  components: {
    BulmaButton,
    LoginModal,
  },
})
export default class extends Vue {
  public modal_visible = false;
  public is_busy = false;
  public readonly store = advent22Store();

  public on_click() {
    if (this.store.is_admin) {
      this.store.logout();
    } else {
      // show login modal
      this.is_busy = true;
      this.modal_visible = true;
    }
  }

  public on_submit(creds: Credentials) {
    this.modal_visible = false;

    this.store
      .login(creds)
      .catch(this.$advent22.alert_user_error)
      .finally(() => (this.is_busy = false));
  }

  public on_cancel() {
    this.modal_visible = false;
    this.is_busy = false;
  }
}
</script>
