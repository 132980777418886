import { DoorSaved } from "./api";
import { Rectangle } from "./rectangle";
import { Vector2D } from "./vector2d";

export class Door {
  public static readonly MIN_DAY = 1;

  private _day = Door.MIN_DAY;
  public position: Rectangle;

  constructor(position: Rectangle);
  constructor(position: Rectangle, day: number);
  constructor(position: Rectangle, day = Door.MIN_DAY) {
    this.day = day;
    this.position = position;
  }

  public get day(): number {
    return this._day;
  }

  public set day(day: unknown) {
    // integer coercion
    const result = Number(day);

    if (isNaN(result)) {
      this._day = Door.MIN_DAY;
    } else {
      this._day = Math.max(Math.floor(result), Door.MIN_DAY);
    }
  }

  public static load(serialized: DoorSaved): Door {
    return new Door(
      new Rectangle(
        new Vector2D(serialized.x1, serialized.y1),
        new Vector2D(serialized.x2, serialized.y2),
      ),
      serialized.day,
    );
  }

  public save(): DoorSaved {
    return {
      day: this.day,
      x1: this.position.origin.x,
      y1: this.position.origin.y,
      x2: this.position.corner.x,
      y2: this.position.corner.y,
    };
  }
}
