export interface AdminConfigModel {
  solution: {
    value: string;
    whitespace: string;
    special_chars: string;
    case: string;
    clean: string;
  };
  puzzle: {
    first: string;
    next: string | null;
    last: string;
    end: string;
    seed: string;
    extra_days: number[];
    skip_empty: boolean;
  };
  calendar: {
    config_file: string;
    background: string;
    favicon: string;
  };
  image: {
    size: number;
    border: number;
  };
  fonts: { file: string; size: number }[];
  redis: {
    host: string;
    port: number;
    db: number;
    protocol: number;
  };
  webdav: {
    url: string;
    cache_ttl: number;
    config_file: string;
  };
}

export interface SiteConfigModel {
  title: string;
  subtitle: string;
  content: string;
  footer: string;
}

export interface NumStrDict {
  [key: number]: string;
}

export interface DoorSaved {
  day: number;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export type Credentials = [username: string, password: string];

export function objForEach<T>(
  obj: T,
  f: (k: keyof T, v: T[keyof T]) => void,
): void {
  for (const k in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, k)) {
      f(k, obj[k]);
    }
  }
}
