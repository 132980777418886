<template>
  <slot v-if="show" name="default" />
  <span v-else>***</span>
  <BulmaButton
    :class="`tag icon is-${button_class} ml-2`"
    :icon="`fa-solid fa-${button_icon}`"
    :busy="busy"
    @click="on_click"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import BulmaButton from "./Button.vue";

enum ClickState {
  Green = 0,
  Yellow = 1,
  Red = 2,
}

@Options({
  components: {
    BulmaButton,
  },
  emits: ["load"],
})
export default class extends Vue {
  public state = ClickState.Green;

  public on_click(): void {
    this.state++;
    this.state %= 3;

    if (this.state === ClickState.Red) {
      this.$emit("load");
    }
  }

  public get show(): boolean {
    return this.state === ClickState.Red;
  }

  public get busy(): boolean {
    return this.state === ClickState.Yellow;
  }

  public get button_class(): string {
    switch (this.state) {
      case ClickState.Red:
        return "danger";
      case ClickState.Yellow:
        return "warning";
      default:
        return "primary";
    }
  }

  public get button_icon(): string {
    if (this.state === ClickState.Red) {
      return "eye-slash";
    } else {
      return "eye";
    }
  }
}
</script>
