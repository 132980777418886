<template>
  <div class="card">
    <header class="card-header is-unselectable" style="cursor: pointer">
      <p class="card-header-title" @click="toggle">{{ header }}</p>

      <p v-if="refreshable" class="card-header-icon px-0">
        <BulmaButton class="tag icon is-primary" @click="refresh">
          <font-awesome-icon
            icon="fa-solid fa-arrows-rotate"
            :spin="is_open && loading"
          />
        </BulmaButton>
      </p>

      <button class="card-header-icon" @click="toggle">
        <span class="icon">
          <font-awesome-icon
            :icon="'fa-solid fa-angle-' + (is_open ? 'down' : 'right')"
          />
        </span>
      </button>
    </header>

    <template v-if="is_open">
      <div v-if="loading" class="card-content">
        <progress class="progress is-primary" max="100" />
      </div>
      <div
        v-else-if="failed"
        class="card-content has-text-danger has-text-centered"
      >
        <span class="icon is-large">
          <font-awesome-icon icon="fa-solid fa-ban" size="3x" />
        </span>
      </div>
      <slot v-else name="default" />
    </template>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import BulmaButton from "./Button.vue";

enum DrawerState {
  Loading,
  Ready,
  Failed,
}

@Options({
  components: {
    BulmaButton,
  },
  props: {
    header: String,
    refreshable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["open"],
})
export default class extends Vue {
  public header!: string;
  public refreshable!: boolean;

  public is_open = false;
  public state = DrawerState.Loading;

  public toggle() {
    this.is_open = !this.is_open;

    if (this.is_open) {
      this.state = DrawerState.Loading;

      this.$emit(
        "open",
        () => (this.state = DrawerState.Ready),
        () => (this.state = DrawerState.Failed),
      );
    }
  }

  public refresh() {
    this.is_open = false;
    this.toggle();
  }

  public get loading(): boolean {
    return this.state === DrawerState.Loading;
  }

  public get failed(): boolean {
    return this.state === DrawerState.Failed;
  }
}
</script>

<style scoped>
div.card:not(:last-child) {
  margin-bottom: 1.5rem;
}
</style>
