<template>
  <foreignObject
    :x="Math.round(store.calendar_aspect_ratio * rectangle.left)"
    :y="rectangle.top"
    :width="Math.round(store.calendar_aspect_ratio * rectangle.width)"
    :height="rectangle.height"
    :style="`transform: scaleX(${1 / store.calendar_aspect_ratio})`"
  >
    <div
      xmlns="http://www.w3.org/1999/xhtml"
      :class="`px-2 is-flex is-align-items-center is-justify-content-center is-size-2 has-text-weight-bold ${extra_classes}`"
      style="height: inherit"
      :title="title"
    >
      <slot name="default" />
    </div>
  </foreignObject>
</template>

<script lang="ts">
import { Rectangle } from "@/lib/rectangle";
import { advent22Store } from "@/plugins/store";
import { Options, Vue } from "vue-class-component";

type BulmaVariant =
  | "primary"
  | "link"
  | "info"
  | "success"
  | "warning"
  | "danger";

@Options({
  props: {
    variant: String,
    visible: {
      type: Boolean,
      default: false,
    },
    rectangle: Rectangle,
    title: {
      type: String,
      required: false,
    },
  },
})
export default class extends Vue {
  public readonly store = advent22Store();

  private variant!: BulmaVariant;
  private visible!: boolean;
  public rectangle!: Rectangle;
  public title?: string;

  public get extra_classes(): string {
    let result = this.variant;

    if (this.visible) result += " visible";

    return result;
  }
}
</script>

<style lang="scss" scoped>
@import "@/bulma-scheme";

foreignObject > div {
  &:not(.visible, :hover):deep() > * {
    display: none;
  }

  &.visible,
  &:hover {
    border-width: 2px;
    border-style: solid;

    @each $name, $color in $advent22-colors {
      &.#{$name} {
        background-color: rgba($color, 0.3);
        border-color: rgba($color, 0.9);
      }
    }
  }
}
</style>
