<template>
  <SVGRect
    variant="primary"
    :visible="store.is_touch_device || force_visible"
    :rectangle="door.position"
  >
    <div
      class="has-text-danger"
      style="text-shadow: 0 0 10px white, 0 0 20px white"
    >
      {{ door.day }}
    </div>
  </SVGRect>
</template>

<script lang="ts">
import { Door } from "@/lib/door";
import { advent22Store } from "@/plugins/store";
import { Options, Vue } from "vue-class-component";

import SVGRect from "./SVGRect.vue";

@Options({
  components: {
    SVGRect,
  },
  props: {
    door: Door,
    force_visible: {
      type: Boolean,
      default: false,
    },
  },
})
export default class extends Vue {
  public readonly store = advent22Store();

  public door!: Door;
  public force_visible!: boolean;
}
</script>
