<template>
  <nav class="breadcrumb has-succeeds-separator">
    <ul>
      <li
        v-for="(step, index) in steps"
        :key="`step-${index}`"
        :class="modelValue === index ? 'is-active' : ''"
        @click.left="change_step(index)"
      >
        <a>
          <span class="icon is-small">
            <font-awesome-icon :icon="step.icon" />
          </span>
          <span>{{ step.label }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

export interface Step {
  label: string;
  icon: string;
}

@Options({
  props: {
    steps: Array,
    modelValue: Number,
  },
  emits: ["update:modelValue"],
})
export default class extends Vue {
  public steps!: Step[];
  public modelValue!: number;

  public change_step(next_step: number) {
    if (next_step === this.modelValue) {
      return;
    }

    this.$emit("update:modelValue", next_step);
  }
}
</script>
