<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 1000 1000"
    preserveAspectRatio="none"
    @contextmenu.prevent
    @mousedown="transform_mouse_event"
    @mousemove="transform_mouse_event"
    @mouseup="transform_mouse_event"
    @click="transform_mouse_event"
    @dblclick="transform_mouse_event"
  >
    <slot name="default" />
  </svg>
</template>

<script lang="ts">
import { Vector2D } from "@/lib/vector2d";
import { advent22Store } from "@/plugins/store";
import { Options, Vue } from "vue-class-component";

function get_event_thous(event: MouseEvent): Vector2D {
  if (!(event.currentTarget instanceof SVGSVGElement)) {
    return new Vector2D();
  }

  return new Vector2D(
    Math.round((event.offsetX / event.currentTarget.clientWidth) * 1000),
    Math.round((event.offsetY / event.currentTarget.clientHeight) * 1000),
  );
}

function mouse_event_validator(event: object, point: object): boolean {
  if (!(event instanceof MouseEvent)) {
    console.warn(event, "is not a MouseEvent!");
    return false;
  }

  if (!(point instanceof Vector2D)) {
    console.warn(point, "is not a Vector2D!");
    return false;
  }

  return true;
}

@Options({
  emits: {
    mousedown: mouse_event_validator,
    mouseup: mouse_event_validator,
    mousemove: mouse_event_validator,
    click: mouse_event_validator,
    dblclick: mouse_event_validator,
  },
})
export default class extends Vue {
  public readonly store = advent22Store();

  public mounted(): void {
    new ResizeObserver(([first, ...rest]) => {
      if (rest.length > 0)
        console.warn(`Unexpected ${rest.length} extra entries!`);

      this.store.set_calendar_aspect_ratio(first.contentRect);
    }).observe(this.$el);
  }

  public transform_mouse_event(event: MouseEvent) {
    const point = get_event_thous(event);
    this.$emit(event.type, event, point);
  }
}
</script>

<style scoped>
svg {
  height: 100%;
  width: 100%;

  position: absolute;
  left: 0;
  top: 0;

  z-index: auto;
}
</style>
