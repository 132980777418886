import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64212ed2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["x", "y", "width", "height"]
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("foreignObject", {
    x: Math.round(_ctx.store.calendar_aspect_ratio * _ctx.rectangle.left),
    y: _ctx.rectangle.top,
    width: Math.round(_ctx.store.calendar_aspect_ratio * _ctx.rectangle.width),
    height: _ctx.rectangle.height,
    style: _normalizeStyle(`transform: scaleX(${1 / _ctx.store.calendar_aspect_ratio})`)
  }, [
    _createElementVNode("div", {
      xmlns: "http://www.w3.org/1999/xhtml",
      class: _normalizeClass(`px-2 is-flex is-align-items-center is-justify-content-center is-size-2 has-text-weight-bold ${_ctx.extra_classes}`),
      style: {"height":"inherit"},
      title: _ctx.title
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 10, _hoisted_2)
  ], 12, _hoisted_1))
}