<template>
  <template v-if="store.is_initialized === true">
    <Calendar :doors="store.user_doors" />
    <hr />
    <div class="content" v-html="store.site_config.content" />
    <div class="content has-text-primary">
      <template v-if="store.next_door_target === null">
        Alle {{ store.user_doors.length }} Türchen offen!
      </template>
      <template v-else>
        <template v-if="store.user_doors.length === 0">
          Zeit bis zum ersten Türchen:
        </template>
        <template v-else>
          {{ store.user_doors.length }} Türchen offen. Zeit bis zum nächsten
          Türchen:
        </template>
        <CountDown :until="store.next_door_target" />
      </template>
    </div>
  </template>
  <progress v-else class="progress is-primary" max="100" />
</template>

<script lang="ts">
import { advent22Store } from "@/plugins/store";
import { Options, Vue } from "vue-class-component";

import Calendar from "./Calendar.vue";
import CountDown from "./CountDown.vue";

@Options({
  components: {
    Calendar,
    CountDown,
  },
})
export default class extends Vue {
  public readonly store = advent22Store();
}
</script>
