import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BulmaButton = _resolveComponent("BulmaButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.show)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : (_openBlock(), _createElementBlock("span", _hoisted_1, "***")),
    _createVNode(_component_BulmaButton, {
      class: _normalizeClass(`tag icon is-${_ctx.button_class} ml-2`),
      icon: `fa-solid fa-${_ctx.button_icon}`,
      busy: _ctx.busy,
      onClick: _ctx.on_click
    }, null, 8, ["class", "icon", "busy", "onClick"])
  ], 64))
}