import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["min"]
const _hoisted_2 = {
  key: 1,
  class: "has-text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGRect = _resolveComponent("SVGRect")!

  return (_openBlock(), _createBlock(_component_SVGRect, {
    style: {"cursor":"text"},
    rectangle: _ctx.door.position,
    variant: _ctx.editing ? 'success' : 'primary',
    onClick: _withModifiers(_ctx.on_click, ["left"]),
    visible: ""
  }, {
    default: _withCtx(() => [
      (_ctx.editing)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.day_str) = $event)),
            ref: "day_input",
            class: "input is-large",
            type: "number",
            min: _ctx.MIN_DAY,
            placeholder: "Tag",
            onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.on_keydown && _ctx.on_keydown(...args)))
          }, null, 40, _hoisted_1)), [
            [_vModelText, _ctx.day_str]
          ])
        : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.door.day > 0 ? _ctx.door.day : "*"), 1))
    ]),
    _: 1
  }, 8, ["rectangle", "variant", "onClick"]))
}