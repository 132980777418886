import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginModal = _resolveComponent("LoginModal")!
  const _component_BulmaButton = _resolveComponent("BulmaButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.modal_visible)
      ? (_openBlock(), _createBlock(_component_LoginModal, {
          key: 0,
          onSubmit: _ctx.on_submit,
          onCancel: _ctx.on_cancel
        }, null, 8, ["onSubmit", "onCancel"]))
      : _createCommentVNode("", true),
    _createVNode(_component_BulmaButton, _mergeProps(_ctx.$attrs, {
      icon: 'fa-solid fa-toggle-' + (_ctx.store.is_admin ? 'on' : 'off'),
      busy: _ctx.is_busy,
      text: "Admin",
      onClick: _withModifiers(_ctx.on_click, ["left"])
    }), null, 16, ["icon", "busy", "onClick"])
  ], 64))
}