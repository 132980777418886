<template>
  <section class="hero is-small is-primary">
    <div class="hero-body">
      <h1 class="title is-uppercase">{{ store.site_config.title }}</h1>
      <h2 class="subtitle">{{ store.site_config.subtitle }}</h2>
    </div>
  </section>

  <section class="section px-3">
    <div class="container">
      <AdminView v-if="store.is_admin" />
      <UserView v-else />
    </div>
  </section>

  <div class="is-flex-grow-1" />

  <footer class="footer">
    <div class="level">
      <div class="level-item">
        <p v-html="store.site_config.footer" />
      </div>
      <div class="level-right">
        <div class="level-item">
          <TouchButton class="tag is-warning" />
        </div>
        <div class="level-item">
          <AdminButton class="tag is-link is-outlined" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { advent22Store } from "./plugins/store";

import AdminView from "./components/admin/AdminView.vue";
import AdminButton from "./components/AdminButton.vue";
import TouchButton from "./components/TouchButton.vue";
import UserView from "./components/UserView.vue";

@Options({
  components: {
    AdminView,
    AdminButton,
    TouchButton,
    UserView,
  },
})
export default class extends Vue {
  public readonly store = advent22Store();
}
</script>

<style>
html {
  overflow-y: auto !important;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
