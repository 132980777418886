<template>
  <div class="card-content">
    <div class="content is-small">
      <h3>Steuerung</h3>
      <ul>
        <li>Linksklick: Türchen bearbeiten</li>
        <li>Tastatur: Tag eingeben</li>
        <li>[Enter]: Tag speichern</li>
        <li>[Esc]: Eingabe Abbrechen</li>
        <li>[Entf]: Tag entfernen</li>
      </ul>
    </div>
    <figure class="image is-unselectable">
      <img :src="store.calendar_background_image" />
      <ThouCanvas>
        <PreviewDoor
          v-for="(door, index) in doors"
          :key="`door-${index}`"
          :door="door"
        />
      </ThouCanvas>
    </figure>
  </div>
</template>

<script lang="ts">
import { Door } from "@/lib/door";
import { advent22Store } from "@/plugins/store";
import { Options, Vue } from "vue-class-component";

import ThouCanvas from "../calendar/ThouCanvas.vue";
import PreviewDoor from "./PreviewDoor.vue";

@Options({
  components: {
    ThouCanvas,
    PreviewDoor,
  },
  props: {
    doors: Array,
  },
})
export default class extends Vue {
  public doors!: Door[];
  public readonly store = advent22Store();
}
</script>
