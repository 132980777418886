import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "breadcrumb has-succeeds-separator" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "icon is-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `step-${index}`,
          class: _normalizeClass(_ctx.modelValue === index ? 'is-active' : ''),
          onClick: _withModifiers(($event: any) => (_ctx.change_step(index)), ["left"])
        }, [
          _createElementVNode("a", null, [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_font_awesome_icon, {
                icon: step.icon
              }, null, 8, ["icon"])
            ]),
            _createElementVNode("span", null, _toDisplayString(step.label), 1)
          ])
        ], 10, _hoisted_2))
      }), 128))
    ])
  ]))
}