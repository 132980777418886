import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "has-text-danger",
  style: {"text-shadow":"0 0 10px white, 0 0 20px white"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGRect = _resolveComponent("SVGRect")!

  return (_openBlock(), _createBlock(_component_SVGRect, {
    variant: "primary",
    visible: _ctx.store.is_touch_device || _ctx.force_visible,
    rectangle: _ctx.door.position
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.door.day), 1)
    ]),
    _: 1
  }, 8, ["visible", "rectangle"]))
}