<template>
  {{ string_repr }}
</template>

<script lang="ts">
import { Duration } from "luxon";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    until: Number,
    tick_time: {
      type: Number,
      default: 200,
    },
  },
})
export default class extends Vue {
  private until!: number;
  private tick_time!: number;

  private interval_id: number | null = null;
  public string_repr = "";

  private tick(): void {
    const distance_ms = this.until - Date.now();

    if (distance_ms <= 0) {
      this.string_repr = "Jetzt!";
      return;
    }

    const distance = Duration.fromMillis(distance_ms);
    const d_days = distance.shiftTo("day").mapUnits(Math.floor);
    const d_hms = distance.minus(d_days).shiftTo("hour", "minute", "second");

    if (d_days.days > 0) {
      this.string_repr = d_days.toHuman() + " ";
    } else {
      this.string_repr = "";
    }
    this.string_repr += d_hms.toFormat("hh:mm:ss");
  }

  public mounted(): void {
    this.tick();
    this.interval_id = window.setInterval(this.tick, this.tick_time);
  }

  public beforeUnmount(): void {
    if (this.interval_id === null) return;
    window.clearInterval(this.interval_id);
  }
}
</script>
