<template>
  <div style="display: none">
    <div v-bind="$attrs" ref="message">
      <slot name="default" />
    </div>
  </div>
</template>

<script lang="ts">
import * as bulmaToast from "bulma-toast";
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["handle"],
})
export default class extends Vue {
  public created(): void {
    this.$emit("handle", this);
  }

  public show(options: bulmaToast.Options = {}) {
    if (!(this.$refs.message instanceof HTMLElement)) return;

    bulmaToast.toast({
      ...options,
      single: true,
      message: this.$refs.message,
    });
  }

  public hide() {
    if (!(this.$refs.message instanceof HTMLElement)) return;

    const toast_div = this.$refs.message.parentElement;
    if (!(toast_div instanceof HTMLDivElement)) return;

    const dbutton = toast_div.querySelector("button.delete");
    if (!(dbutton instanceof HTMLButtonElement)) return;

    dbutton.click();
  }
}
</script>
